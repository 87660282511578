<style scoped>
.customer-tab-wrapper {
  min-width: 100px;
}
.active-tab {
  /* background-color: #358cff; */
  background-color: var(--tab-color);
  color: #fff !important;
}
.customer-tab_number {
  font-size: 25px;
  font-weight: 700;
  text-align: left;
}
.customer-tab_text {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
#status-v-chip .v-chip {
  font-size: 12px;
  font-weight: 800;
}
#status-v-chip .v-chip.lead {
  background: #faf9e0 !important;
  color: #b5ae00 !important;
}
#status-v-chip .v-chip.business_details {
  background: #e7f7fb !important;
  color: #31bee8 !important;
}
#status-v-chip .v-chip.personal_details {
  background: #f8eefe !important;
  color: #b554f1 !important;
}
#status-v-chip .v-chip.document_details {
  background: #e7eff6 !important;
  color: #337ab7 !important;
}
#status-v-chip .v-chip.basic_details {
  background: #feebf0 !important;
  color: #ff5b8c !important;
}
#status-v-chip .v-chip.applied {
  background: #fbe6f5 !important;
  color: #ef2cb9 !important;
}
#status-v-chip .v-chip.under_process {
  background: #fdf2e3 !important;
  color: #f0ad4e !important;
}
#status-v-chip .v-chip.profile_complete {
  background: #def1de !important;
  color: #5cb85c !important;
}
#status-v-chip .v-chip.Address {
  background: #e0e6f8 !important;
  color: #24377d !important;
}
/* #status-v-chip .v-chip.disbursed {
  background: #70707019 !important;
  color: #2d2d2dc6 !important;
} */
#status-v-chip .v-chip.rejected_by {
  background: #fc000019 !important;
  color: #d11010c6 !important;
}
#status-v-chip .v-chip.disbursed {
  background: #d48d001e !important;
  color: #d48d00 !important;
}
#status-v-chip .v-chip.completed {
  background: #58ea0430 !important;
  color: #58ea04 !important;
}
.blocked-tag-wrapper {
  position: relative;
  overflow: initial;
}
.blocked-tag-star {
  position: absolute;
  top: -10px;
  right: -10px;
}
</style>
<template>
  <div>
    <!-- filters -->
    <v-row class="pt-1 mx-0">
      <v-col class="text-center" cols="12" md="3">
        <v-menu
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.display_daterange"
              v-on="on"
              class="filter"
              dense
              filled
              label="Filter by Date Range"
              outlined
              placeholder="Filter by Date Range"
              readonly
              single-line
            >
              <template slot="append">
                <v-icon
                  v-if="filters.date_range.length > 1"
                  @click="clearDateFilter()"
                  >mdi-close</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.date_range" :max="today" range>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                filters.date_range = [];
                date_menu = false;
              "
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col class="text-center" cols="12" md="3">
        <v-combobox
          v-model="filters.current_city"
          :items="city_list"
          :loading="city_loading"
          :search-input.sync="city_search"
          clearable
          dense
          filled
          hide-details
          label="Select city"
          outlined
          placeholder="Select city"
          single-line
          @change="getCustomerList(true)"
          @focus="getCitylist"
          @keyup="getCitylist"
        >
          <template v-slot:append-item>
            <div v-intersect="endIntersect"></div>
          </template>
        </v-combobox>
      </v-col>
      <v-col class="text-center" cols="12" md="3">
        <v-menu
          v-model="fd_date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.fd_display_date"
              v-on="on"
              :label="$lang.FOLLOW_UP_DATE"
              :placeholder="$lang.FOLLOW_UP_DATE"
              class="filter"
              dense
              filled
              outlined
              readonly
              single-line
            >
              <template slot="append">
                <v-icon
                  v-if="filters.follow_up_date"
                  @click="
                    filters.follow_up_date = null;
                    filters.fd_display_date = null;
                    clearDateFilter();
                  "
                  >mdi-close
                </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.follow_up_date">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="date_menu = false"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <div>
      <!-- tabs -->
      <v-tabs show-arrows v-model="filters.selected_tab" height="95px">
        <v-tab
          v-for="(item, id) in tabs_title"
          :key="id"
          class="customer-tab"
          active-class="active-tab"
          :style="cssValue"
          @click="tabClicked(item)"
        >
          <div class="customer-tab-wrapper">
            <div class="customer-tab_number">
              <!-- {{ parseInt(item.value).toLocaleString("en-IN") }} -->
              {{ item.count }}
              <!-- {{item.param}} -->
              <!-- {{ item.color }} -->
            </div>
            <div class="customer-tab_text">
              {{ item.title }}
            </div>
          </div>
        </v-tab>
      </v-tabs>

      <!-- tab content -->
      <div class="mt-6">
        <!-- :headers="is_rejected_table ? rejectHeaders : headers" -->
        <div>
          <v-data-table
            :disable-sort="true"
            :items="customer_list"
            :loading="table_loading"
            :headers="headers"
            :items-per-page="$keys.PAGE_LENGTH"
            class="border-a border-0 custome-header-bg"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear
                bottom
                indeterminate
                class="lighten1"
                style="width: 103%; margin-left: -20px"
              >
              </v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="table_loading" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">Loading items...</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length == 0" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">No data found</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-for="item in items" :key="item.loan_id">
                <tr>
                  <td>{{ item.customer_id }}</td>

                  <td class="">
                    <div
                      id="status-v-chip"
                      v-if="item.is_business_loan_eligible"
                    >
                      <v-chip :class="statusChipClass(item.profile_status)">
                        {{ item.profile_status }}
                      </v-chip>
                    </div>
                    <div v-else>
                      <v-tooltip left bottom class="d-flex">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="d-flex align-center mt-1"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <div id="status-v-chip">
                              <v-chip
                                outlined
                                color="red"
                                class="blocked-tag-wrapper"
                              >
                                <div
                                  v-if="
                                    item.profile_status == 'Rejected by Cashpo'
                                  "
                                  class="blocked-tag-star"
                                >
                                  <v-icon color="error">
                                    mdi-star-circle
                                  </v-icon>
                                </div>
                                <v-icon small color="red" class="pr-1"
                                  >mdi-cancel</v-icon
                                >
                                Blocked
                              </v-chip>
                            </div>
                          </div>
                        </template>
                        <span>
                          <ul v-for="item in item.blocked_at" :key="item">
                            <li v-if="item == 'No Entity Type'">
                              No Business Registration
                            </li>
                            <li v-else>{{ item }}</li>
                          </ul>
                        </span>
                      </v-tooltip>
                    </div>
                    <!-- <div
                      v-if="!item.is_business_loan_eligible"
                      class="py-2 red--text font-weight-bold d-flex align-center"
                    >
                      <v-icon small color="red">mdi-cancel</v-icon>
                      <div class="pt-1 pl-1">
                        Blocked
                      </div>
                    </div> -->
                  </td>
                  <td>
                    <p class="pt-2">
                      {{ item.full_name }}
                    </p>
                    <p class="py-1" v-if="item.mobile">{{ item.mobile }}</p>
                    <p class="pb-2" v-if="item.email">{{ item.email }}</p>
                  </td>
                  <td>{{ item.fathers_name }}</td>
                  <td>{{ item.initiated_on }}</td>
                  <td>{{ item.proceed_at_stage_on }}</td>
                  <td>{{ item.current_city }}, {{ item.current_state }}</td>
                  <td>
                    <div v-if="item.loan_amount">
                      ₹{{ item.loan_amount.toLocaleString("en-IN") }}
                    </div>
                    <div v-else>-</div>
                  </td>
                  <td>
                    <div v-if="item.entity_type">
                      {{ formateEntityType(item.entity_type) }}
                    </div>
                    <div v-else>-</div>
                  </td>
                  <!-- <td v-if="is_rejected_table">
                    <p v-if="item.agent_assigned">{{ item.agent_assigned }}</p>
                    <p v-else>-</p>
                  </td>
                  <td v-else>
                    <p
                      v-if="item.agent_assigned"
                      class="cursor-pointer"
                      @click="openAssignAgent(item)"
                    >
                      {{ item.agent_assigned }}
                    </p>
                    <p
                      v-else
                      class="blue--text text-caption cursor-pointer"
                      @click="openAssignAgent(item)"
                    >
                      <v-icon x-small color="blue">mdi-pencil</v-icon>
                      <span class="text-decoration-underline"
                        >Assign Agent</span
                      >
                    </p>
                  </td> -->
                  <!-- <td>
                    <p v-if="item.alloted_nbfc">{{ item.alloted_nbfc }}</p>
                    <p v-else>-</p>
                  </td> -->
                  <!-- <td v-if="is_rejected_table">
                    <p v-if="item.rejection_reason">
                      {{ item.rejection_reason }}
                    </p>
                    <p v-else>-</p>
                  </td>
                  <td v-if="is_rejected_table">
                    <p v-if="item.rejection_on">{{ item.rejection_on }}</p>
                    <p v-else>-</p>
                  </td>-->
                  <td>
                    <p v-if="item.follow_up_date">
                      {{ item.follow_up_date }}
                    </p>
                    <p v-else>-</p>
                  </td>
                  <td>
                    <p v-if="item.remark">
                      {{ item.remark }}
                    </p>
                    <p v-else>-</p>
                  </td>
                  <td>
                    <v-btn
                      color="secondary_2"
                      fab
                      small
                      text
                      @click="addComment(item)"
                      class="mr-1"
                    >
                      <v-icon>mdi-message-text-outline</v-icon>
                    </v-btn>
                    <v-btn fab small text @click="viewNewCustomerDetails(item)">
                      <v-icon class="primary_2--text">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <v-pagination
      v-model="filters.page_number"
      :length="total_page_count"
      :total-visible="7"
      class="custome-pagination"
      @input="getCustomerList(true)"
    ></v-pagination>

    <FollowupDateDialog ref="followup_dialog" @update="getCustomerList" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    FollowupDateDialog: () =>
      import("@/components/business-loan/dialog/FollowupDateDialog.vue"),
  },
  data() {
    return {
      tabColor: "",
      PreVerificationDialogloading: false,
      activeTab: "",
      profile_step: "",
      fd_date_menu: false,
      headers: [
        {
          text: "Lead No.",
          align: "start",
          sortable: false,
          value: "customer_id",
          class: "primary_2 white--text",
        },
        {
          text: "Profile Status",
          align: "center",
          sortable: false,
          value: "profile_status",
          class: "primary_2 white--text",
        },
        {
          text: "Customer Info",
          value: "name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Father's Name",
          value: "father_name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Signed-up At",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Initaited On",
          value: "proceed_at_stage_on",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "City & State",
          value: "city",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Applied Amount",
          value: "applied_amount",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Entity Type",
          value: "entity_type",
          sortable: false,
          class: "primary_2 white--text",
        },
        // {
        //   text: "Agent",
        //   value: "agent_assigned",
        //   sortable: false,
        //   class: "primary_2 white--text",
        // },
        // {
        //   text: "NBFC",
        //   value: "alloted_nbfc",
        //   sortable: false,
        //   class: "primary_2 white--text",
        // },
        {
          text: "Follow-up Date",
          value: "follow_up_date",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 120,
          class: "primary_2 white--text",
        },
      ],

      rejectHeaders: [
        {
          text: "Lead No.",
          align: "start",
          sortable: false,
          value: "customer_id",
          class: "primary_2 white--text",
        },
        {
          text: "Customer Info",
          value: "name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Father's Name",
          value: "father_name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Signed-up At",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "City",
          value: "city",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Initiated On",
          value: "initiated_on",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Applied Amount",
          value: "applied_amount",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Agent",
          value: "agent_assigned",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "NBFC",
          value: "alloted_nbfc",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Rejection Reason",
          value: "rejection_reason",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Rejected On",
          value: "rejected_on",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Follow-up Date",
          value: "follow_up_date",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 120,
          class: "primary_2 white--text",
        },
      ],
      is_rejected_table: false,

      loans: [],
      table_loading: false,
      page_number: 1,
      total_page_count: 2,

      customer_list: [],

      tabs_title: [],

      active_table: "",

      date_menu: false,
      display_daterange: null,
      today: new Date().toISOString().substr(0, 10),
      city_list: [],
      city_search_query: "",
      city_loading: false,
      city_page: 1,
      city_search: "",
      pre_verification_dialog_data: {
        flag: false,
        data: [],
      },
      comment_dialog: {
        flag: false,
        comment: null,
        follow_up_date: null,
        label: null,
        id: null,
      },
      assign_agent_dialog: {
        flag: false,
        loan_id: null,
        agent: "",
      },

      agent_list: [],
      agent_loading: false,
      agent_page: 1,
      agent_search: "",
      agent: "",
      status_date_menu: false,
      // agent_type: "",
      agent_type_list: [
        {
          id: 1,
          text: "Assigned",
          value: true,
        },
        {
          id: 2,
          text: "Unassigned",
          value: false,
        },
      ],
      // document_type: "",
      document_type_list: [
        {
          id: 1,
          text: "Rent Address Proof",
          value: "address_proof",
        },
        {
          id: 2,
          text: "Bank Statement",
          value: "bank_statement",
        },
        {
          id: 3,
          text: "Both",
          value: "both",
        },
      ],
      // document_request_status: "",
    };
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
      filters: "business_loan/getFilters",
    }),
    cssValue() {
      return {
        "--tab-color": this.tabColor,
      };
    },
    itemsWithSno() {
      return this.customer_list.map((d, index) => ({
        ...d,
        sno: index + 1,
      }));
    },
    document_request_status_list() {
      if (this.filters.document_type == "both") {
        let list = [
          {
            id: 1,
            text: "Not Requested",
            value: "Not Requested",
          },
          {
            id: 2,
            text: "Requested",
            value: "Requested",
          },
          {
            id: 3,
            text: "Uploaded",
            value: "Uploaded",
          },
          {
            id: 4,
            text: "Partial Uploaded",
            value: "Partial Uploaded",
          },
        ];
        return list;
      } else {
        let list = [
          {
            id: 1,
            text: "Not Requested",
            value: "Not Requested",
          },
          {
            id: 2,
            text: "Requested",
            value: "Requested",
          },
          {
            id: 3,
            text: "Uploaded",
            value: "Uploaded",
          },
        ];
        return list;
      }
    },
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      this.getCustomerList(true);
    },
    "filters.agent_type": function(value) {
      if (!value) {
        this.filters.agent_assigned = "";
        this.getCustomerList(true);
      }
    },
  },

  created() {
    console.log(this.$route.meta.show_back_btn);
    this.getCustomerList(true);
  },

  // destroyed() {
  //   this.filters.agent_assigned = "";
  // },
  methods: {
    statusChipClass(item) {
      let status = item.toLowerCase().replace(" ", "_");
      return status;
    },
    formateEntityType(value) {
      let text = value.replace("_", " ").toUpperCase();
      return text;
    },
    clearDocRequestStatus() {
      this.filters.document_request_status = "";
      this.getCustomerList(true);
    },
    openAssignAgent(value) {
      this.assign_agent_dialog.flag = true;
      this.assign_agent_dialog.loan_id = value.loan_id;
      this.assign_agent_dialog.agent = value.agent_assigned;
    },
    clearStatusDateFilter() {
      this.daterange = [];
      this.filters.display_status_daterange = null;
      this.filters.status_date_range = [];
      this.getCustomerList(true);
    },
    updateAgent(value) {
      let loan_id = value.loan_id;
      let agent_assigned = value.agent_assigned;

      for (let i = 0; i < this.customer_list.length; i++) {
        if (this.customer_list[i].loan_id == loan_id) {
          this.customer_list[i].agent_assigned = agent_assigned;
        }
      }
    },
    // change profile_status value on tab click
    tabClicked(value) {
      let reject = value.param.search("Rejected");
      if (reject == 0) {
        this.is_rejected_table = true;
      } else {
        this.is_rejected_table = false;
      }
      this.filters.precheck_status = value.param;
      this.tabColor = value.color;
      this.getCustomerList(true);
    },

    viewNewCustomerDetails(value) {
      //   this.$store.dispatch("pre_verification/setActiveLoanId", value.loan_id);
      this.$router.push({
        name: "business-loan-details",
        params: {
          id: this.encript(value.customer_id),
        },
      });
    },

    // fetch table data from server
    getCustomerList(isCancel = false) {
      const self = this;
      self.table_loading = true;
      self.customer_list = [];
      let look_up_key = "";
      // if (self.filters.agent_assigned) {
      //   var agent_search_query = self.filters.agent_assigned.name;
      // }

      let params = {
        page_number: this.filters.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        business_loan_status: this.filters.precheck_status
          ? this.filters.precheck_status
          : "All",
        from_date: this.filters.date_range[0],
        to_date: this.filters.date_range[1],
        follow_up_date: this.filters.follow_up_date,
        city: self.filters.current_city,
        // // agent_search_query: agent_search_query,
        // loan_from_date: this.filters.status_date_range[0],
        // loan_to_date: this.filters.status_date_range[1],
        // is_assigned: this.filters.agent_type,
        // document_type: this.filters.document_type,
        // document_request_status: this.filters.document_request_status,
      };

      // if (this.filters.agent_assigned && this.filters.agent_type) {
      //   params.agent_search_query = this.filters.agent_assigned.name;
      // }

      const successHandler = (response) => {
        // console.log("business", response);
        self.table_loading = false;
        self.customer_list = [];
        self.customer_list = response.data.result.data;
        self.tabs_title = response.data.result.allcount;
        self.total_page_count = response.data.result.total_page_count;
        if (!self.tabColor)
          self.tabColor = response.data.result.allcount[0].color;
      };

      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {};

      self.POST_request(
        self,
        self.$urls.GET_BUSINESS_LOAN_LIST,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler,
        isCancel
      );
    },

    /* fetching the cit list form server*/
    getCitylist(append_city = false) {
      const self = this;
      self.city_loading = true;
      let params = {
        search_query: this.city_search,
        page_number: this.city_page,
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          if (append_city)
            self.city_list = self.city_list.concat(response.data.city_list);
          else self.city_list = response.data.city_list;
        }
      };
      const finallyHandler = () => {
        self.city_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.CITY_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.city_page++;
        this.getCitylist(true);
      }
    },
    endIntersectAgent(entries, observer, isIntersectingAgent) {
      if (isIntersectingAgent) {
        // this.agent_page++;
        this.getAgentList(true);
      }
    },
    formateDisplayDate() {
      if (this.filters.follow_up_date)
        this.filters.fd_display_date = this.display_date_formate(
          this.filters.follow_up_date
        );
      if (this.filters.date_range.length > 1)
        this.filters.display_daterange = this.formateDisplayDateRange(
          this.filters.date_range
        );
      if (this.filters.status_date_range.length > 1)
        this.filters.display_status_daterange = this.formateDisplayDateRange(
          this.filters.status_date_range
        );
      this.date_menu = false;
      this.status_date_menu = false;
      this.fd_date_menu = false;
      this.getCustomerList(true);
    },
    clearDateFilter() {
      this.daterange = [];
      this.filters.display_daterange = null;
      this.filters.date_range = [];
      this.getCustomerList(true);
    },
    formateDisplayDateRange(daterange) {
      console.log(daterange);
      let date1 = daterange[0];
      let date2 = daterange[1];
      let to = "";
      let from = "";
      if (date1 > date2) {
        to = date1;
        from = date2;
      } else {
        to = date2;
        from = date1;
      }
      console.log(from);
      if (!to) to = from;
      daterange[0] = from;
      daterange[1] = to;
      from = this.display_date_formate(from);
      to = this.display_date_formate(to);
      return `${from} to ${to}`;
    },
    addComment(item) {
      // this.comment_dialog.comment = item.comment == " - " ? "" : item.comment;
      // this.comment_dialog.id = item.customer_id;
      // this.comment_dialog.follow_up_date = item.follow_up_date;
      // this.comment_dialog.label = item.label;
      // this.comment_dialog.flag = true;
      this.$refs.followup_dialog.openFollowupDialog(item);
    },
    getAgentList(append_agent = false) {
      const self = this;
      self.agent_loading = true;
      let params = {
        search_query: this.agent_search,
        page_number: this.agent_page,
      };
      console.log(params);
      const successHandler = (response) => {
        // console.log("Team list",response);
        self.agent_list = response.data.result.user_data;
        self.agent_list.unshift({
          name: "All",
        });
      };
      const finallyHandler = () => {
        self.agent_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.TEAM_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
