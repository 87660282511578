var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{staticClass:"py-2",attrs:{"show-arrows":"","height":"95px"},model:{value:(_vm.filters.selected_tab),callback:function ($$v) {_vm.$set(_vm.filters, "selected_tab", $$v)},expression:"filters.selected_tab"}},_vm._l((_vm.tabs_item),function(item,id){return _c('v-tab',{key:id,style:(_vm.cssValue),attrs:{"active-class":"active-tab"},on:{"click":function($event){return _vm.tabClicked(item)}}},[_c('div',{staticClass:"tab-wrapper"},[_c('div',{staticClass:"tab_number"},[_vm._v(" "+_vm._s(item.count)+" ")]),_c('div',{staticClass:"tab_text"},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),1),_c('v-data-table',{attrs:{"items":_vm.items_list,"headers":_vm.headers,"loading":_vm.table_loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"pt-2"},[_vm._v(_vm._s(item.full_name))]),_c('p',{staticClass:"py-1"},[_vm._v(_vm._s(item.mobile))]),_c('p',{staticClass:"pb-2"},[_vm._v(_vm._s(item.email))])]}},{key:"item.dob",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('span',[_vm._v(" "+_vm._s(item.date_of_birth))])])]}},{key:"item.disbursed_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[(item.disbursed_amount)?_c('span',[_vm._v(" ₹"+_vm._s(item.disbursed_amount.toLocaleString("en-IN")))]):_c('span',[_vm._v(" -")])])]}},{key:"item.applied_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[(item.applied_amount)?_c('span',[_vm._v(" ₹"+_vm._s(item.applied_amount.toLocaleString("en-IN")))]):_c('span',[_vm._v(" -")])])]}},{key:"item.last_updated_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[(item.last_updated_date)?_c('span',[_vm._v(" "+_vm._s(_vm.display_date_formate(item.last_updated_date)))]):_c('span',[_vm._v(" -")])])]}},{key:"item.type_of_loan",fn:function(ref){
var item = ref.item;
return [(item.type_of_loan)?_c('div',{staticClass:"py-2"},_vm._l((item.type_of_loan),function(loan,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(loan))])])}),0):_c('div',[_vm._v("-")])]}},{key:"item.abhiloan_fetched_data",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_c('span',{staticClass:"font-weight-black"},[_vm._v("APV: ")]),(item.avail_portfolio_value.availablePortfolioValue)?_c('span',[_vm._v(" ₹"+_vm._s(item.avail_portfolio_value.availablePortfolioValue.toLocaleString( "en-IN" ))+" ")]):_c('span',[_vm._v("-")])]),_c('div',[_c('span',{staticClass:"font-weight-black"},[_vm._v("TPV: ")]),(item.avail_portfolio_value.totalPortfolioValue)?_c('span',[_vm._v(" ₹"+_vm._s(item.avail_portfolio_value.totalPortfolioValue.toLocaleString( "en-IN" ))+" ")]):_c('span',[_vm._v("-")])]),_c('div',[_c('span',{staticClass:"font-weight-black"},[_vm._v("ALV: ")]),(item.avail_portfolio_value.availableLoanValue)?_c('span',[_vm._v(" ₹"+_vm._s(item.avail_portfolio_value.availableLoanValue.toLocaleString( "en-IN" ))+" ")]):_c('span',[_vm._v("-")])])])]}},{key:"item.internal_comment",fn:function(ref){
var item = ref.item;
return [(item.internal_comment)?_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.internal_comment)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"secondary_2","fab":"","small":"","text":""},on:{"click":function($event){return _vm.openCommentDialog(item)}}},[_c('v-icon',[_vm._v("mdi-message-text-outline")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"purple","fab":"","small":"","text":""},on:{"click":function($event){return _vm.openUpdateStatusDialog(item)}}},[_c('v-icon',[_vm._v("mdi-send-variant")])],1)],1)]}}],null,true)}),_c('div',[_c('v-pagination',{staticClass:"custome-pagination",attrs:{"length":_vm.total_page_count,"total-visible":7},on:{"input":function($event){return _vm.get50FinData(true)}},model:{value:(_vm.filters.page_number),callback:function ($$v) {_vm.$set(_vm.filters, "page_number", $$v)},expression:"filters.page_number"}})],1),_c('AddLasComments',{ref:"openLasCommentDialog",on:{"update":_vm.get50FinData}}),_c('UpdateStatus',{ref:"update_las_status_dialog",on:{"update":_vm.get50FinData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }