<template>
  <div class="pa-4">
    <v-row class="ma-0">
      <v-col cols="12">
        <v-expansion-panels class="mb-4 ">
          <v-expansion-panel class="neumorphism-pressed filter-panel">
            <v-expansion-panel-header class="filter-panel-title">
              Filters
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="py-4 mx-0">
                <v-col cols="12" md="3" lg="3" xl="3">
                  <v-menu
                    v-model="date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filters.display_daterange"
                        v-on="on"
                        class="filter"
                        dense
                        filled
                        label="Filter by Signup Date Range"
                        outlined
                        background-color="#fff"
                        placeholder="Filter by Signup Date Range"
                        readonly
                        single-line
                      >
                        <template slot="append">
                          <v-icon
                            v-if="filters.date_range.length > 1"
                            @click="clearDateFilter()"
                            >mdi-close</v-icon
                          >
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.date_range"
                      :max="today"
                      range
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="
                          filters.date_range = [];
                          date_menu = false;
                        "
                        >Cancel</v-btn
                      >
                      <v-btn color="primary" text @click="formateDisplayDate()"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3" lg="3" xl="3">
                  <v-menu
                    v-model="applied_date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filters.applied_display_daterange"
                        v-on="on"
                        class="filter"
                        dense
                        filled
                        label="Filter by Applied date"
                        outlined
                        background-color="#fff"
                        placeholder="Filter by Applied date"
                        readonly
                        single-line
                      >
                        <template slot="append">
                          <v-icon
                            v-if="filters.applied_date_range.length > 1"
                            @click="clearAppliedDateFilter()"
                            >mdi-close</v-icon
                          >
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.applied_date_range"
                      :max="today"
                      range
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="
                          filters.applied_date_range = [];
                          applied_date_menu = false;
                        "
                        >Cancel</v-btn
                      >
                      <v-btn color="primary" text @click="formateDisplayDate()"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3" lg="3" xl="3">
                  <!-- <div class="neumorphism-pressed topic-field"> -->
                  <v-select
                    v-model="filters.alloted_nbfc"
                    class=""
                    dense
                    outlined
                    hide-details
                    background-color="#fff"
                    :items="alloted_nbfc_list"
                    placeholder="Select Alloted NBFC"
                    clearable
                    @change="filters.loan_status = ''"
                  ></v-select>
                  <!-- </div> -->
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  lg="3"
                  xl="3"
                  v-if="filters.alloted_nbfc"
                >
                  <!-- <div class="neumorphism-pressed topic-field"> -->
                  <v-select
                    v-model="filters.loan_status"
                    class=""
                    dense
                    background-color="#fff"
                    hide-details
                    clearable
                    :items="loan_status_list"
                    placeholder="Select Loan Status"
                    outlined
                  ></v-select>
                  <!-- </div> -->
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="12">
        <v-tabs show-arrows v-model="filters.selected_tab" height="95px">
          <!-- <v-tab
            v-for="(item, id) in tabs_title"
            :key="id"
            active-class="active-tab"
            :style="cssValue"
            @click="tabClicked(item)"
          >
            <div class="customer-tab-wrapper">
              <div class="customer-tab_number">
                <div v-if="item.count">
                  {{ item.count.toLocaleString("en-IN") }}
                </div>
                <div v-else>0</div>
              </div>
              <div class="customer-tab_text">
                {{ item.title }}
              </div>
            </div>
          </v-tab> -->
          <v-tab
            v-for="(item, id) in tabs_title"
            :key="id"
            active-class="active-tab"
            :style="cssValue"
            @click="tabClicked(item)"
          >
            <div class="tab-wrapper">
              <div class="tab_number">
                {{ item.count }}
              </div>
              <div class="tab_text">
                {{ item.title }}
              </div>
            </div>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="12">
        <v-data-table
          :disable-sort="true"
          :headers="headers"
          :items="users_list"
          :loading="table_loading"
          :items-per-page="$keys.PAGE_LENGTH"
          hide-default-footer
          loading-text="Loading...."
        >
          <template v-slot:[`item.customer_details`]="{ item }">
            <p class="pt-2">{{ item.name }}</p>
            <p class="py-1">{{ item.mobile }}</p>
            <p class="pb-2">{{ item.email }}</p>
          </template>
          <template v-slot:[`item.city`]="{ item }">
            <div>{{ item.city }}, {{ item.state }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              fab
              small
              text
              @click="viewNewCustomerDetails(item.lead_number)"
            >
              <v-icon class="primary_2--text">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.nbfc_details`]="{ item }">
            <div v-if="item.nbfc_details[0]" class="font-weight-bold">
              <div v-for="(nbfc, index) in item.nbfc_details" :key="index">
                <div
                  v-if="nbfc.nbfc_name == 'Banksathi'"
                  class="d-flex align-center"
                >
                  BankSathi -
                  <div class="ml-1">
                    <div v-if="nbfc.offer_details.pl_offer_count" class="my-2">
                      <v-chip
                        color="cyan lighten-4"
                        class="font-weight-medium"
                        @click="
                          openOffersList(
                            'Personal Loan',
                            nbfc.offer_details.offer_list.pl_offer_list
                          )
                        "
                      >
                        PL: <b> {{ nbfc.offer_details.pl_offer_count }}</b>
                      </v-chip>
                    </div>
                    <div v-if="nbfc.offer_details.cc_offer_count">
                      <v-chip
                        color="purple lighten-4"
                        class="font-weight-medium"
                        @click="
                          openOffersList(
                            'Credit Card',
                            nbfc.offer_details.offer_list.cc_offer_list
                          )
                        "
                      >
                        CC: <b> {{ nbfc.offer_details.cc_offer_count }}</b>
                      </v-chip>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ nbfc.nbfc_name }} -
                  <v-tooltip v-if="nbfc.nbfc_status == 'rejected'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" v-bind="attrs" v-on="on">
                        mdi-close-circle-outline
                      </v-icon>
                    </template>
                    <span>{{ nbfc.rejection_reason }}</span>
                  </v-tooltip>
                  <v-icon
                    v-else-if="nbfc.nbfc_status == 'approved'"
                    color="success"
                  >
                    mdi-check-circle-outline
                  </v-icon>
                  <v-icon
                    v-else-if="nbfc.nbfc_status == 'pending'"
                    color="warning"
                  >
                    mdi-minus-circle-outline
                  </v-icon>
                </div>
              </div>
            </div>
            <div v-else>-</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col>
        <v-pagination
          v-model="filters.page_number"
          :length="total_page_count"
          :total-visible="7"
          class="custome-pagination"
          @input="getCustomerList(true)"
        ></v-pagination>
      </v-col>
    </v-row>
    <OffersList ref="offers_list" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    OffersList: () =>
      import("@/components/pre-verification/dialog/OfferList.vue"),
  },
  data() {
    return {
      date_menu: false,
      applied_date_menu: false,
      tabColor: "",
      tabs_title: [],
      users_list: [],
      table_loading: false,
      total_page_count: 2,
      alloted_nbfc_list: ["Fibe", "LTFS", "Banksathi"],
      loan_status_list: ["Approved", "Pending", "Rejected"],
      today: new Date().toISOString().substr(0, 10),
    };
  },
  mounted() {
    this.getCustomerList();
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      this.getCustomerList(true);
    },
    "filters.loan_status": function() {
      this.getCustomerList(true);
    },
    "filters.alloted_nbfc": function() {
      this.getCustomerList(true);
    },
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
      filters: "pl_funnel/getFilters",
    }),
    cssValue() {
      return {
        // "--tab-color": `${this.tabColor}45`,
        "--tab-color": `${this.tabColor}`,
      };
    },
    headers() {
      switch (this.filters.user_status) {
        case "Leads":
          return this.$pl_funnel_headers.lead_headers;
        case "Internal EC":
          return this.$pl_funnel_headers.interal_ec_headers;
        case "Approved":
          return this.$pl_funnel_headers.interal_ec_headers;
        default:
          // return this.$pl_funnel_headers.lead_headers;
          return this.$pl_funnel_headers.interal_ec_headers;
      }
    },
  },
  methods: {
    clearDateFilter() {
      this.daterange = [];
      this.filters.display_daterange = null;
      this.filters.date_range = [];
      this.filters.page_number = 1;
      this.getCustomerList(true);
    },
    clearAppliedDateFilter() {
      this.filters.applied_display_daterange = null;
      this.filters.applied_date_range = [];
      this.filters.page_number = 1;
      this.getCustomerList(true);
    },
    formateDisplayDate() {
      if (this.filters.date_range.length > 1)
        this.filters.display_daterange = this.formateDisplayDateRange(
          this.filters.date_range
        );
      if (this.filters.applied_date_range.length > 1)
        this.filters.applied_display_daterange = this.formateDisplayDateRange(
          this.filters.applied_date_range
        );
      this.date_menu = false;
      this.applied_date_menu = false;
      this.filters.page_number = 1;
      this.getCustomerList(true);
    },
    formateDisplayDateRange(daterange) {
      // console.log(daterange);
      let date1 = daterange[0];
      let date2 = daterange[1];
      let to = "";
      let from = "";
      if (date1 > date2) {
        to = date1;
        from = date2;
      } else {
        to = date2;
        from = date1;
      }
      // console.log(from);
      if (!to) to = from;
      daterange[0] = from;
      daterange[1] = to;
      from = this.display_date_formate(from);
      to = this.display_date_formate(to);
      return `${from} to ${to}`;
    },
    tabClicked(value) {
      this.filters.page_number = 1;
      this.filters.user_status = value.param;
      this.tabColor = value.color;
      this.getCustomerList(true);
    },
    clearLoanStatus() {
      this.filters.loan_status = "";
    },
    viewNewCustomerDetails(id) {
      this.$router.push({
        name: "new-customer-details",
        params: {
          id: this.encript(id),
        },
      });
    },
    getCustomerList(isCancel = false) {
      const self = this;
      self.users_list = [];
      self.table_loading = true;
      let look_up_key = "";

      let params = {
        page_number: this.filters.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        user_status: this.filters.user_status,
        from_date: this.filters.date_range[0],
        to_date: this.filters.date_range[1],
        loan_from_date: this.filters.applied_date_range[0],
        loan_to_date: this.filters.applied_date_range[1],
      };
      if (this.filters.alloted_nbfc) {
        params.alloted_nbfc = this.filters.alloted_nbfc;
      }
      if (this.filters.loan_status) {
        params.loan_status = this.filters.loan_status;
      }

      // console.log(params);
      const successHandler = (response) => {
        self.table_loading = false;
        self.users_list = [];
        self.users_list = response.data.result.data;
        self.total_page_count = response.data.result.total_page_count;
        self.tabs_title = response.data.result.allcount;
        if (!self.tabColor)
          self.tabColor = response.data.result.allcount[0].color;
      };

      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {
        self.table_loading = false;
      };

      self.GET_request(
        self,
        self.$urls.GET_USER_TRACK_RECORD,
        look_up_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler,
        isCancel
      );
    },
    openOffersList(title, list) {
      this.$refs.offers_list.openOffersListDialog(title, list);
    },
  },
};
</script>
<style scoped>
.filter-panel-title {
  font-weight: 800;
  font-size: 20px;
}
/* .active-tab {
  background-color: var(--tab-color);
  font-weight: 800;
  color: #fff !important;
  font-size: 17px;
} */
.list-section-wrapper {
  padding: 15px;
}
/* --------------- */

.topic-field {
  /* background: blue; */
  /* width: 100%; */
  border-radius: 40px;
  padding: 5px 20px 15px 20px;
  border-color: #8bbbe557 !important;
}

.active-tab {
  /* background-color: #358cff; */
  background-color: var(--tab-color);
  color: #fff !important;
}
.tab_number {
  font-size: 25px;
  font-weight: 700;
  text-align: left;
}
.tab_text {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
.tab-wrapper {
  min-width: 100px;
}
</style>
