<template>
  <div>
    <v-tabs
      show-arrows
      v-model="filters.selected_tab"
      height="95px"
      class="py-2"
    >
      <v-tab
        v-for="(item, id) in tabs_item"
        :key="id"
        active-class="active-tab"
        :style="cssValue"
        @click="tabClicked(item)"
      >
        <div class="tab-wrapper">
          <div class="tab_number">
            {{ item.count }}
          </div>
          <div class="tab_text">
            {{ item.title }}
          </div>
        </div>
      </v-tab>
    </v-tabs>
    <v-data-table
      :items="items_list"
      :headers="headers"
      :loading="table_loading"
      hide-default-footer
    >
      <template v-slot:[`item.name`]="{ item }">
        <p class="pt-2">{{ item.full_name }}</p>
        <p class="py-1">{{ item.mobile }}</p>
        <p class="pb-2">{{ item.email }}</p>
      </template>
      <template v-slot:[`item.dob`]="{ item }">
        <div class="py-2">
          <span> {{ item.date_of_birth }}</span>
        </div>
      </template>
      <template v-slot:[`item.disbursed_amount`]="{ item }">
        <div class="py-2">
          <span v-if="item.disbursed_amount">
            ₹{{ item.disbursed_amount.toLocaleString("en-IN") }}</span
          >
          <span v-else> -</span>
        </div>
      </template>
      <template v-slot:[`item.applied_amount`]="{ item }">
        <div class="py-2">
          <span v-if="item.applied_amount">
            ₹{{ item.applied_amount.toLocaleString("en-IN") }}</span
          >
          <span v-else> -</span>
        </div>
      </template>
      <template v-slot:[`item.last_updated_date`]="{ item }">
        <div class="py-2">
          <span v-if="item.last_updated_date">
            {{ display_date_formate(item.last_updated_date) }}</span
          >
          <span v-else> -</span>
        </div>
      </template>
      <template v-slot:[`item.type_of_loan`]="{ item }">
        <div class="py-2" v-if="item.type_of_loan">
          <div v-for="(loan, index) in item.type_of_loan" :key="index">
            <div>{{ loan }}</div>
          </div>
        </div>
        <div v-else>-</div>
      </template>
      <template v-slot:[`item.abhiloan_fetched_data`]="{ item }">
        <div>
          <div>
            <span class="font-weight-black">APV: </span>
            <span v-if="item.avail_portfolio_value.availablePortfolioValue">
              ₹{{
                item.avail_portfolio_value.availablePortfolioValue.toLocaleString(
                  "en-IN"
                )
              }}
            </span>
            <span v-else>-</span>
          </div>
          <div>
            <span class="font-weight-black">TPV: </span>
            <span v-if="item.avail_portfolio_value.totalPortfolioValue">
              ₹{{
                item.avail_portfolio_value.totalPortfolioValue.toLocaleString(
                  "en-IN"
                )
              }}
            </span>
            <span v-else>-</span>
          </div>
          <div>
            <span class="font-weight-black">ALV: </span>
            <span v-if="item.avail_portfolio_value.availableLoanValue">
              ₹{{
                item.avail_portfolio_value.availableLoanValue.toLocaleString(
                  "en-IN"
                )
              }}
            </span>
            <span v-else>-</span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.internal_comment`]="{ item }">
        <div class="py-2" v-if="item.internal_comment">
          {{ item.internal_comment }}
        </div>
        <div v-else>-</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-btn
            color="secondary_2"
            fab
            small
            text
            @click="openCommentDialog(item)"
            class="mr-1"
          >
            <v-icon>mdi-message-text-outline</v-icon>
          </v-btn>

          <v-btn
            color="purple"
            fab
            small
            text
            class="mr-1"
            @click="openUpdateStatusDialog(item)"
          >
            <v-icon>mdi-send-variant</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <div>
      <v-pagination
        v-model="filters.page_number"
        :length="total_page_count"
        :total-visible="7"
        class="custome-pagination"
        @input="get50FinData(true)"
      ></v-pagination>
    </div>
    <AddLasComments ref="openLasCommentDialog" @update="get50FinData" />
    <UpdateStatus ref="update_las_status_dialog" @update="get50FinData" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    AddLasComments: () => import("@/components/las/AddComment.vue"),
    UpdateStatus: () => import("@/components/las/UpdateStatus.vue"),
  },
  data() {
    return {
      tabs_item: [],
      search: "",
      headers: [
        {
          text: "Lead No.",
          align: "start",
          sortable: false,
          value: "id",
          class: "primary_2 white--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary_2 white--text",
        },
        // {
        //   text: "Mobile No.",
        //   align: "start",
        //   sortable: false,
        //   value: "mobile",
        //   class: "primary_2 white--text",
        // },
        {
          text: "Signed-up on",
          align: "start",
          sortable: false,
          value: "initiated_on",
          class: "primary_2 white--text",
        },
        {
          text: "Date Of Birth",
          align: "start",
          sortable: false,
          value: "dob",
          class: "primary_2 white--text",
        },
        // {
        //   text: "Email",
        //   align: "start",
        //   sortable: false,
        //   value: "email",
        //   class: "primary_2 white--text",
        // },
        {
          text: "PAN No.",
          align: "start",
          sortable: false,
          value: "pan",
          class: "primary_2 white--text",
        },
        {
          text: "Last updated on",
          align: "start",
          sortable: false,
          value: "last_updated_date",
          class: "primary_2 white--text",
        },
        {
          text: "Applied Amount",
          align: "start",
          sortable: false,
          value: "applied_amount",
          class: "primary_2 white--text",
        },
        {
          text: "Disbursed Amount",
          align: "start",
          sortable: false,
          value: "disbursed_amount",
          class: "primary_2 white--text",
        },
        {
          text: "AbhiLoan Fetched Data",
          align: "start",
          sortable: false,
          value: "abhiloan_fetched_data",
          class: "primary_2 white--text",
        },
        {
          text: "Comments",
          align: "start",
          sortable: false,
          value: "internal_comment",
          class: "primary_2 white--text",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
          class: "primary_2 white--text",
        },
      ],
      items_list: [],
      table_loading: false,
      tabColor: "",
      total_page_count: null,
    };
  },
  watch: {
    //   search() {
    //     this.debounceGetLasData();
    //   },
    search_query() {
      this.get50FinData(true);
    },
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
      filters: "las/getFilters",
    }),
    cssValue() {
      return {
        "--tab-color": this.tabColor,
      };
    },
  },
  // created() {
  //   this.debounceGetLasData = _.debounce(function() {
  //     this.get50FinData();
  //   }, 500);
  // },
  mounted() {
    this.get50FinData();
  },
  methods: {
    tabClicked(value) {
      // console.log("tabClicked", value);
      // console.log("filters.selected_tab", this.filters.selected_tab);
      this.filters.nbfc_name = value.param;
      this.tabColor = value.color;
      this.get50FinData(true);
    },
    openCommentDialog(item) {
      // console.log("comment item", item);
      this.$refs.openLasCommentDialog.openCommentsDialog(item);
    },
    openUpdateStatusDialog(item) {
      this.$refs.update_las_status_dialog.openUpdateLasStatus(item);
    },
    get50FinData(isCancel = false) {
      const self = this;
      self.table_loading = true;
      const lookup_key = "";
      self.items_list = [];
      // self.tabs_item = [];
      let params = {
        page_number: this.filters.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        partner_name: this.filters.nbfc_name ? this.filters.nbfc_name : "All",
      };
      const successHandler = (response) => {
        self.items_list = response.data.result.data;
        self.tabs_item = response.data.result.allcount;
        self.total_page_count = response.data.result.total_page_count;
        if (!self.tabColor) {
          self.tabColor = response.data.result.allcount[0].color;
        }
        self.table_loading = false;
        if (this.filters.nbfc_name == "All") {
          this.filters.selected_tab = 0;
        }
      };
      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_LAS_LOAN_LIST,
        lookup_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler,
        isCancel
      );
    },
  },
};
</script>
// .active-tab { // /* background-color: #F5E8C7; */ // /* width: 500px; */ //
/* background-color: #003399; */ // color: #003399 !important; // }
<style scoped>
.active-tab {
  /* background-color: #358cff; */
  background-color: var(--tab-color);
  color: #fff !important;
}
.tab_number {
  font-size: 25px;
  font-weight: 700;
  text-align: left;
}
.tab_text {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
.tab-wrapper {
  min-width: 100px;
}
</style>
