<style scoped>
.customer-tab-wrapper {
  min-width: 100px;
}
.active-tab {
  background-color: #358cff;
  color: #fff !important;
}
.customer-tab_number {
  font-size: 25px;
  font-weight: 700;
  text-align: left;
}
.customer-tab_text {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
#task-v-chip .v-chip {
  font-size: 12px;
  font-weight: 800;
}
.kyc-task-v-chip .v-chip {
  font-size: 12px;
  font-weight: 800;
}
</style>
<template>
  <div>
    <!-- filters -->
    <v-row class="pt-1 mx-0">
      <v-col class="text-center" cols="12" md="3">
        <v-menu
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.display_daterange"
              v-on="on"
              class="filter"
              dense
              filled
              label="Filter by Date Range"
              outlined
              placeholder="Filter by Date Range"
              readonly
              single-line
            >
              <template slot="append">
                <v-icon
                  v-if="filters.date_range.length > 1"
                  @click="clearDateFilter()"
                  >mdi-close</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.date_range" :max="today" range>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                filters.date_range = [];
                date_menu = false;
              "
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col class="text-center" cols="12" md="3">
        <v-combobox
          v-model="filters.current_city"
          :items="city_list"
          :loading="city_loading"
          :search-input.sync="city_search"
          clearable
          dense
          filled
          hide-details
          label="Select city"
          outlined
          placeholder="Select city"
          single-line
          @change="getLoanList(true)"
          @focus="getCitylist"
          @keyup="getCitylist"
        >
          <template v-slot:append-item>
            <div v-intersect="endIntersect"></div>
          </template>
        </v-combobox>
      </v-col>
      <v-col class="text-center" cols="12" md="3">
        <v-menu
          v-model="fd_date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.fd_display_date"
              v-on="on"
              :label="$lang.FOLLOW_UP_DATE"
              :placeholder="$lang.FOLLOW_UP_DATE"
              class="filter"
              dense
              filled
              outlined
              readonly
              single-line
            >
              <template slot="append">
                <v-icon
                  v-if="filters.follow_up_date"
                  @click="
                    filters.follow_up_date = null;
                    filters.fd_display_date = null;
                    clearDateFilter();
                  "
                  >mdi-close
                </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.follow_up_date">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="date_menu = false"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        v-if="
          this.filters.loan_status == 'step_disbursed' ||
            this.filters.loan_status == 'step_applied'
        "
        class="text-center"
        cols="12"
        md="3"
      >
        <v-menu
          v-model="status_date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.display_status_daterange"
              v-on="on"
              class="filter"
              dense
              filled
              label="Filter by Status date"
              outlined
              placeholder="Filter by Status date"
              readonly
              single-line
            >
              <template slot="append">
                <v-icon
                  v-if="filters.status_date_range.length > 1"
                  @click="clearStatusDateFilter()"
                  >mdi-close</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.status_date_range" :max="today" range>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                filters.status_date_range = [];
                status_date_menu = false;
              "
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="text-center" cols="12" md="3">
        <v-select
          :items="agent_type_list"
          item-value="value"
          item-text="text"
          v-model="filters.agent_type"
          class="filter"
          dense
          clearable
          filled
          label="Select Agent Type"
          outlined
          placeholder="Select Agent Type"
        >
        </v-select>
      </v-col>
      <v-col v-if="filters.agent_type" class="text-center" cols="12" md="3">
        <v-combobox
          v-model="filters.agent_assigned"
          :items="agent_list"
          :loading="agent_loading"
          :search-input.sync="agent_search"
          clearable
          dense
          filled
          hide-details
          item-text="name"
          item-value="id"
          label="Select agent"
          outlined
          placeholder="Select agent"
          single-line
          @focus="getAgentList"
          @keyup="getAgentList"
          @change="getLoanList(true)"
        >
          <template v-slot:append-item>
            <div v-intersect="endIntersectAgent"></div>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <div>
      <!-- tabs -->
      <v-tabs show-arrows v-model="filters.selected_tab" height="95px">
        <v-tab
          v-for="(item, id) in tabs"
          :key="id"
          class="customer-tab"
          active-class="active-tab"
          @click="tabClicked(item)"
        >
          <div class="customer-tab-wrapper">
            <div class="customer-tab_number">
              {{ parseInt(item.value).toLocaleString("en-IN") }}
            </div>
            <div class="customer-tab_text">
              {{ item.title }}
            </div>
          </div>
        </v-tab>
      </v-tabs>

      <!-- tab content -->
      <div class="mt-6">
        <!-- table for rejected loan status -->
        <div v-if="filters.loan_status == 'step_rejected'">
          <v-data-table
            :disable-sort="true"
            :headers="reject_headers"
            :items="loans"
            :loading="table_loading"
            :items-per-page="$keys.PAGE_LENGTH"
            class="border-a border-0 custome-header-bg"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear
                bottom
                indeterminate
                class="lighten1"
                style="width: 103%; margin-left: -20px"
              >
              </v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="table_loading" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">Loading items...</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length == 0" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">No data found</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-for="item in items" :key="item.loan_id">
                <tr @dblclick="viewNewCustomerDetails(item.customer_id)">
                  <td>{{ item.customer_id }}</td>
                  <td>{{ item.lms_customer_id }}</td>
                  <td>
                    <p class="pt-2">{{ item.name }}</p>
                    <p class="py-1">{{ item.mobile }}</p>
                    <p class="pb-2">{{ item.email }}</p>
                    <v-chip
                      v-if="item.pushed_to_ltfs"
                      outlined
                      color="success"
                      class="mb-2"
                    >
                      Pushed To LTFS
                    </v-chip>
                  </td>
                  <td>{{ item.rejection_reason }}</td>
                  <td>₹{{ item.applied_amount.toLocaleString("en-IN") }}</td>
                  <td>{{ item.initiated_on }}</td>
                  <td>{{ item.city }}</td>
                  <td>
                    <div v-if="item.created">
                      <p>{{ new Date(item.created).toLocaleTimeString() }},</p>
                      <p>
                        {{ new Date(item.created).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td>
                    <p v-if="item.agent_assigned">{{ item.agent_assigned }}</p>
                    <p v-else>-</p>
                  </td>
                  <td>
                    <div v-if="item.follow_up_date">
                      <p>
                        {{ new Date(item.follow_up_date).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td>
                    <p v-if="item.comment">
                      {{ item.comment }}
                    </p>
                    <p v-else>
                      -
                    </p>
                  </td>
                  <td>
                    <v-btn
                      color="secondary_2"
                      fab
                      small
                      text
                      @click="addComment(item)"
                      class="mr-1"
                    >
                      <v-icon>mdi-message-text-outline</v-icon>
                    </v-btn>
                    <v-btn
                      color="purple"
                      fab
                      small
                      text
                      class="mr-1"
                      @click="openPushToLtfsDialog('Rejected', item)"
                    >
                      <v-icon>mdi-send-variant</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      small
                      text
                      @click="viewNewCustomerDetails(item.customer_id)"
                    >
                      <v-icon class="primary_2--text">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
        <!-- table for other status -->
        <div v-else>
          <v-data-table
            :disable-sort="true"
            :headers="headers"
            :items="loans"
            :loading="table_loading"
            :items-per-page="$keys.PAGE_LENGTH"
            class="border-a border-0 custome-header-bg"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear
                bottom
                indeterminate
                class="lighten1"
                style="width: 103%; margin-left: -20px"
              >
              </v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="table_loading" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">Loading items...</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length == 0" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">No data found</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-for="item in items" :key="item.loan_id">
                <tr @dblclick="viewNewCustomerDetails(item.customer_id)">
                  <td>{{ item.customer_id }}</td>
                  <td>{{ item.lms_customer_id }}</td>
                  <td>
                    <p class="pt-2">{{ item.name }}</p>
                    <p class="py-1">{{ item.mobile }}</p>
                    <p class="pb-2">{{ item.email }}</p>
                    <v-chip
                      v-if="item.pushed_to_ltfs"
                      outlined
                      color="success"
                      class="mb-2"
                    >
                      Pushed To LTFS
                    </v-chip>
                  </td>
                  <td>₹{{ item.applied_amount.toLocaleString("en-IN") }}</td>
                  <td>{{ item.initiated_on }}</td>
                  <td>{{ item.city }}</td>
                  <td>
                    <div v-if="item.created">
                      <p>
                        {{ item.created }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td>
                    <p
                      v-if="item.agent_assigned"
                      class="cursor-pointer"
                      @click="openAssignAgent(item)"
                    >
                      {{ item.agent_assigned }}
                    </p>
                    <p
                      v-else
                      class="blue--text text-caption cursor-pointer"
                      @click="openAssignAgent(item)"
                    >
                      <v-icon x-small color="blue">mdi-pencil</v-icon>
                      <span class="text-decoration-underline"
                        >Assign Agent</span
                      >
                    </p>
                  </td>
                  <td>
                    <div v-if="item.follow_up_date">
                      <p>
                        {{ new Date(item.follow_up_date).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td>
                    <p v-if="item.comment">
                      {{ item.comment }}
                    </p>
                    <p v-else>
                      -
                    </p>
                  </td>
                  <td>
                    <v-btn
                      color="secondary_2"
                      fab
                      small
                      text
                      @click="addComment(item)"
                      class="mr-1"
                    >
                      <v-icon>mdi-message-text-outline</v-icon>
                    </v-btn>
                    <v-btn
                      color="purple"
                      fab
                      small
                      text
                      v-if="filters.loan_status == 'step_completed'"
                      class="mr-1"
                      @click="openPushToLtfsDialog('Completed', item)"
                    >
                      <v-icon>mdi-send-variant</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      small
                      text
                      @click="viewNewCustomerDetails(item.customer_id)"
                    >
                      <v-icon class="primary_2--text">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <v-pagination
      v-model="filters.page_number"
      :length="total_page_count"
      :total-visible="7"
      class="custome-pagination"
      @input="getLoanList(true)"
    ></v-pagination>

    <AddComments
      ref="comment_dialog"
      :dialog="comment_dialog"
      @update="getLoanList(true)"
    />
    <AssignAgentDialog
      v-if="assign_agent_dialog.flag"
      :dialog="assign_agent_dialog"
      @update="updateAgent"
    />
    <PushToLtfs ref="la_push_to_ltfs_dialog" @update="getLoanList(true)" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    AddComments: () => import("@/components/customer/dialog/AddComments"),
    AssignAgentDialog: () =>
      import("@/components/loan-applied/AssignAgentDialog"),
    PushToLtfs: () => import("@/components/common/PushToLtfs.vue"),
  },
  data() {
    return {
      loading: false,
      activeTab: "",
      applied_count: 0,
      document_verification_count: 0,
      approved_count: 0,
      sanctioned_count: 0,
      loan_agreement_count: 0,
      video_verification_count: 0,
      disbursed_count: 0,
      profile_completed_count: 0,
      auto_debit_count: 0,
      money_on_the_way_count: 0,
      step_in_settlement: 0,
      completed_count: 0,
      rejected_count: 0,
      // profile_status: "",

      status_date_menu: false,
      isLoanStatusLead: true,

      profile_step: "",
      headers: [
        {
          text: "Lead No.",
          align: "start",
          sortable: false,
          value: "id",
          class: "primary_2 white--text",
        },
        {
          text: "CIF ID",
          align: "start",
          sortable: false,
          value: "lms_customer_id",
          class: "primary_2 white--text",
        },
        {
          text: "Customer Info",
          value: "name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Applied Amount",
          value: "applied_amount",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Initailted On",
          value: "initiated_on",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "City",
          value: "city",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Signed-up At",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Agent",
          value: "agent_assigned",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Follow-up Date",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 120,
          class: "primary_2 white--text",
        },
      ],
      reject_headers: [
        {
          text: "Lead No.",
          align: "start",
          sortable: false,
          value: "id",
          class: "primary_2 white--text",
        },
        {
          text: "CIF ID",
          align: "start",
          sortable: false,
          value: "lms_customer_id",
          class: "primary_2 white--text",
        },
        {
          text: "Customer Info",
          value: "name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Rejection Reason",
          value: "rejection_reason",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Applied Amount",
          value: "applied_amount",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Initailted On",
          value: "initiated_on",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "City",
          value: "city",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Signed-up At",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Agent",
          value: "agent_assigned",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Follow-up Date",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 120,
          class: "primary_2 white--text",
        },
      ],

      loans: [],
      table_loading: false,
      page_number: 1,
      total_page_count: 2,
      new_customer_list: [],

      active_table: "",

      date_menu: false,
      fd_date_menu: false,
      display_daterange: null,
      today: new Date().toISOString().substr(0, 10),
      city_list: [],
      city_search_query: "",
      city_loading: false,
      city_page: 1,
      city_search: "",
      comment_dialog: {
        flag: false,
        comment: null,
        follow_up_date: null,
        label: null,
        id: null,
        name: "",
        mobile: "",
        email: "",
      },
      assign_agent_dialog: {
        flag: false,
        loan_id: null,
        agent: "",
      },

      agent_list: [],
      agent_loading: false,
      agent_page: 1,
      agent_search: "",
      agent: "",
      // agent_type: "",
      agent_type_list: [
        {
          id: 1,
          text: "Assigned",
          value: true,
        },
        {
          id: 2,
          text: "Unassigned",
          value: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
      filters: "loan_applied/getFilters",
    }),
    itemsWithSno() {
      return this.new_customer_list.map((d, index) => ({
        ...d,
        sno: index + 1,
      }));
    },

    tabs() {
      return [
        {
          id: 1,
          title: "Applied",
          key: "step_applied",
          value: `${this.applied_count}`,
        },
        {
          id: 2,
          title: "Document Verified",
          key: "step_document_verified",
          value: `${this.document_verification_count}`,
        },
        {
          id: 3,
          title: "Approved",
          key: "step_approved",
          value: `${this.approved_count}`,
        },
        {
          id: 4,
          title: "Sanctioned",
          key: "step_sanctioned",
          value: `${this.sanctioned_count}`,
        },
        {
          id: 6,
          title: "In Video Verification",
          key: "step_in_person_verification",
          value: `${this.video_verification_count}`,
        },
        {
          id: 7,
          title: "Setup Auto Debit",
          key: "step_setup_auto_debit",
          value: `${this.auto_debit_count}`,
        },
        {
          id: 8,
          title: "Money on the Way",
          key: "step_money_on_the_way",
          // value: `${this.rejected_count}`,
          value: `${this.money_on_the_way_count}`,
        },
        {
          id: 8,
          title: "Settlement",
          key: "step_in_settlement",
          // value: `${this.rejected_count}`,
          value: `${this.step_in_settlement}`,
        },
        {
          id: 9,
          title: "Disbursed",
          key: "step_disbursed",
          // value: `${this.rejected_count}`,
          value: `${this.disbursed_count}`,
        },
        {
          id: 10,
          title: "Completed",
          key: "step_completed",
          // value: `${this.rejected_count}`,
          value: `${this.completed_count}`,
        },
        {
          id: 11,
          title: "Rejected",
          key: "step_rejected",
          // value: `${this.rejected_count}`,
          value: `${this.rejected_count}`,
        },
      ];
    },
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      this.getLoanList(true);
    },
    "filters.agent_type": function(value) {
      if (!value) {
        this.filters.agent_assigned = "";
        this.getLoanList(true);
      }
    },
  },

  created() {
    console.log(this.$route.meta.show_back_btn);
    this.getLoanList(true);
  },

  // destroyed() {
  //   this.filters.agent_assigned = "";
  // },

  methods: {
    // change profile_status value on tab click
    tabClicked(value) {
      this.filters.loan_status = value.key;
      this.getLoanList(true);
    },

    // redirect to customer details page
    viewNewCustomerDetails(id) {
      this.$router.push({
        name: "loan-applied-details",
        params: {
          id: this.encript(id),
        },
      });
    },
    openPushToLtfsDialog(stage, value) {
      this.$refs.la_push_to_ltfs_dialog.openPushToLtfs(stage, value);
    },
    updateAgent(value) {
      let loan_id = value.loan_id;
      let agent_assigned = value.agent_assigned;

      for (let i = 0; i < this.loans.length; i++) {
        if (this.loans[i].loan_id == loan_id) {
          this.loans[i].agent_assigned = agent_assigned;
        }
      }
    },

    openAssignAgent(value) {
      this.assign_agent_dialog.flag = true;
      this.assign_agent_dialog.loan_id = value.loan_id;
      this.assign_agent_dialog.agent = value.agent_assigned;
    },
    clearStatusDateFilter() {
      this.daterange = [];
      this.filters.display_status_daterange = null;
      this.filters.status_date_range = [];
      this.getLoanList();
    },
    // fetch table data from server
    getLoanList(isCancel = false) {
      const self = this;
      self.table_loading = true;
      self.loans = [];
      let look_up_key = "";

      if (self.filters.agent_assigned) {
        var agent_search_query = self.filters.agent_assigned.name;
      }

      let params = {
        page_number: this.filters.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        loan_status: this.filters.loan_status,
        from_date: this.filters.date_range[0],
        to_date: this.filters.date_range[1],
        follow_up_date: this.filters.follow_up_date,
        city: self.filters.current_city,
        // agent_search_query: agent_search_query,
        loan_from_date: this.filters.status_date_range[0],
        loan_to_date: this.filters.status_date_range[1],
        is_assigned: this.filters.agent_type,
      };

      if (self.filters.agent_assigned && this.filters.agent_type) {
        params.agent_search_query = self.filters.agent_assigned.name;
      }
      // console.log(params);
      const successHandler = (response) => {
        self.table_loading = false;
        self.loans = [];
        // console.log("loan list",response);
        this.loans = response.data.result.customer_list;
        this.applied_count = response.data.result.step_applied;
        this.document_verification_count =
          response.data.result.step_document_verified;
        this.approved_count = response.data.result.step_approved;
        this.sanctioned_count = response.data.result.step_sanctioned;
        this.video_verification_count =
          response.data.result.step_in_person_verification;
        this.auto_debit_count = response.data.result.step_setup_auto_debit;
        this.money_on_the_way_count =
          response.data.result.step_money_on_the_way;
        this.disbursed_count = response.data.result.step_disbursed;
        this.step_in_settlement = response.data.result.step_in_settlement;
        this.completed_count = response.data.result.step_completed;
        this.rejected_count = response.data.result.step_rejected;
        self.total_page_count = response.data.result.total_page_count;
      };

      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {};

      self.GET_request(
        self,
        self.$urls.LOANS_LIST,
        look_up_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler,
        isCancel
      );
    },

    /* fetching the cit list form server*/
    getCitylist(append_city = false) {
      const self = this;
      self.city_loading = true;
      let params = {
        search_query: this.city_search,
        page_number: this.city_page,
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          if (append_city)
            self.city_list = self.city_list.concat(response.data.city_list);
          else self.city_list = response.data.city_list;
        }
      };
      const finallyHandler = () => {
        self.city_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.CITY_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    clearKycStep() {
      this.filters.kyc_status = "";
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.city_page++;
        this.getCitylist(true);
      }
    },
    endIntersectAgent(entries, observer, isIntersectingAgent) {
      if (isIntersectingAgent) {
        // this.agent_page++;
        this.getAgentList(true);
      }
    },
    formateDisplayDate() {
      if (this.filters.follow_up_date)
        this.filters.fd_display_date = this.display_date_formate(
          this.filters.follow_up_date
        );
      if (this.filters.date_range.length > 1)
        this.filters.display_daterange = this.formateDisplayDateRange(
          this.filters.date_range
        );
      if (this.filters.status_date_range.length > 1)
        this.filters.display_status_daterange = this.formateDisplayDateRange(
          this.filters.status_date_range
        );
      this.date_menu = false;
      this.status_date_menu = false;
      this.fd_date_menu = false;
      this.getLoanList(true);
    },
    clearDateFilter() {
      this.daterange = [];
      this.filters.display_daterange = null;
      this.filters.date_range = [];
      this.getLoanList(true);
    },
    formateDisplayDateRange(daterange) {
      console.log(daterange);
      let date1 = daterange[0];
      let date2 = daterange[1];
      let to = "";
      let from = "";
      if (date1 > date2) {
        to = date1;
        from = date2;
      } else {
        to = date2;
        from = date1;
      }
      console.log(from);
      if (!to) to = from;
      daterange[0] = from;
      daterange[1] = to;
      from = this.display_date_formate(from);
      to = this.display_date_formate(to);
      return `${from} to ${to}`;
    },
    addComment(item) {
      this.comment_dialog.comment = item.comment == " - " ? "" : item.comment;
      this.comment_dialog.id = item.customer_id;
      this.comment_dialog.follow_up_date = item.follow_up_date;
      this.comment_dialog.name = item.name;
      this.comment_dialog.mobile = item.mobile;
      this.comment_dialog.email = item.email;
      this.comment_dialog.label = item.label;
      this.comment_dialog.flag = true;
      this.$refs.comment_dialog.formateDisplayDate();
    },
    getAgentList(append_agent = false) {
      const self = this;
      self.agent_loading = true;
      let params = {
        search_query: this.agent_search,
        page_number: this.agent_page,
      };
      console.log(params);
      const successHandler = (response) => {
        // console.log("Team list",response);
        self.agent_list = response.data.result.user_data;
        self.agent_list.unshift({
          name: "All",
        });
      };
      const finallyHandler = () => {
        self.agent_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.TEAM_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
